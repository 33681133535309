<template>
  <section id="assortment" class="assortment-container">
    <div class="columns is-multiline">
      <header-item :title="'Asortyment'" />
      <assortment-item
        v-for="(category, index) in categories"
        :key="index"
        :image="category.image"
        :imageAlt="category.name"
        :title="category.name"
        :short-description="category.shortDescription"
        @click.native="openAssortmentModal(index)"
      />
      <assortment-modal
        v-if="modalOpen"
        :modalData="categories[modalIndex]"
        :class="{ 'is-active': modalOpen }"
        @closeAssortmentModal="closeAssortmentModal()"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Assortment',
  data() {
    return {
      modalIndex: null,
      modalOpen: false,
      categories: [
        {
          name: 'INSTALACJE',
          shortDescription:
            'Systemy instalacyjne wodno-kanalizacyjne, gaz i CO.',
          description: [
            'rury i kształtki stalowe, kwasoodporne, miedziane, klejone PCV, zgrzewane PP, skręcane PE, spawane',
            'kanalizacja wewnętrzna i zewnętrzna',
            'zawory, filtry, wężyki, armatura: gazowa, wodna, centralnego ogrzewania, do powietrza',
            'wentylatory, kanały, kratki wentylacyjne',
            'orynnowanie',
            'ogrzewacze wody pojemnościowe i przepływowe, wymienniki',
            'baterie i części do baterii',
            'ceramika sanitarna, wyposażenie łazienki i kuchni',
          ],
          image: '01.b.webp',
          imageFull: '01.a.webp',
        },
        {
          name: 'ELEKTROTECHNIKA',
          shortDescription:
            'Kable, przewody i przedłużacze. Oprawy i źródła światła.',
          description: [
            'żarówki, świetlówki, halogeny, lampy wyładowcze, oprawy oświetleniowe',
            'gniazda i łączniki, przedłużacze, sznury, przewody, wtyczki, puszki, koryta kablowe, rury',
            'aparaty modułowe, rozdzielnice na tynkowe i podtynkowe, osprzęt siłowy, wyłączniki silnikowe, styczniki, przekaźniki, przyciski i kasety, bezpieczniki',
            'osprzęt telefoniczny i radiowo-telewizyjny',
          ],
          image: '02.b.webp',
          imageFull: '02.a.webp',
        },
        {
          name: 'TECHNIKA ZAMOCOWAŃ',
          shortDescription:
            'Śruby stalowe, nierdzewne, kwasoodporne. Kołki i kotwy.',
          description: [
            'śruby maszynowe, zamkowe, imbusowe, nakrętki, podkładki ocynkowane i nierdzewne',
            'wkręty do drewna, metalu, blachowkręty',
            'nity aluminiowe, stalowe, nitonakrętki',
            'kołki rozporowe, kotwy stalowe, zamocowania do płyt gips.-karton.',
            'pręty gwintowane ocynkowane i nierdzewne',
            'gwoździe budowlane, skrętne, pierścieniowe, papowe, ciesielskie, stolarskie, hartowane',
            'łańcuchy, liny stalowe, osprzęt do lin',
            'plomby, drut do plombowania',
          ],
          image: '03.b.webp',
          imageFull: '03.a.webp',
        },
        {
          name: 'NARZĘDZIA',
          shortDescription:
            'Elektronarzędzia i osprzęt. Narzędzia pomiarowe i warsztatowe.',
          description: [
            'narzędzia dla instalatorów, elektryków, stolarzy, cieśli, spawaczy, wyposażenie warsztatów ślusarskich i samochodowych, dla majsterkowiczów',
            'narzędzia do obróbki materiałów: piły i brzeszczoty, wiertła, narzynki i gwintowniki, pilniki, dłuta, ściernice i materiały ścierne, otwornice',
            'narzędzia pomiarowe: miary, taśmy miernicze, suwmiarki, kątomierze, poziomnice',
            'elektronarzędzia i osprzęt',
          ],
          image: '04.b.webp',
          imageFull: '04.a.webp',
        },
        {
          name: 'PNEUMATYKA',
          shortDescription: 'Złączki, węże, siłowniki, sprężyny, napędy.',
          description: [
            'napędy pneumatyczne',
            'zawory i elektrozawory pneumatyczne',
            'technika przygotowania powietrza',
            'węże i przewody pneumatyczne',
            'złącza i szybkozłącza do węży',
            'sprężyny gazowe',
            'technika próżniowa',
          ],
          image: '05.b.webp',
          imageFull: '05.a.webp',
        },
        {
          name: 'SPAWALNICTWO',
          shortDescription: 'Elektrody i druty. Osprzęt gazowy i elektryczny.',
          description: [
            'elektrody i drut spawalniczy',
            'reduktory i manometry',
            'palniki i dysze',
            'przewody spawalnicze',
            'maski, uchwyty i rękawice spawalnicze',
          ],
          image: '06.b.webp',
          imageFull: '06.a.webp',
        },
        {
          name: 'ARTYKUŁY METALOWE',
          shortDescription:
            'Zamki, wkładki, kłódki, zawiasy, okucia i łączniki budowlane.',
          description: [
            'zamki drzwiowe wierzchnie i wpuszczane, meblowe, wkładki patentowe, kłódki, klucze',
            'klamki drzwiowe i okienne, gałki, uchwyty',
            'zawiasy drzwiowe, bramowe, okienne',
            'zasuwy, baskwile',
            'samozamykacze',
          ],
          image: '07.b.webp',
          imageFull: '07.a.webp',
        },
        {
          name: 'MATERIAŁY TECHNICZNE',
          shortDescription: 'Sznury, pasy, liny, łańcuchy oraz osprzęt.',
          description: [
            'łopaty, szpadle, grabie,  kilofy,  siekiery,  trzonki drewniane',
            'liny,  sznury,  powrozy,  sznurki ogrodowe',
            'taśmy samoprzylepne,  ostrzegawcze,  aluminiowe,  specjalne',
            'smary, środki konserwujące,  silikony,  pianki',
            'węże do: wody,  powietrza, oleju, benzyny, gazów wraz ze złączkami',
            'elektrody, druty i osprzęt spawalniczy',
          ],
          image: '08.b.webp',
          imageFull: '08.a.webp',
        },
        {
          name: 'BHP',
          shortDescription:
            'Środki ochrony osobistej. Środki czystości. Tablice budowlane.',
          description: [
            'rękawice budowlane, ogrodowe, gospodarcze, specjalistyczne, spawalnicze',
            'odzież ochronna: spodnie, koszule, kurtki oraz buty',
            'okulary, gogle, maski, ochronniki słuchu, kaski',
            'tablice informacyjne i ostrzegawcze',
          ],
          image: '09.b.webp',
          imageFull: '09.a.webp',
        },
        {
          name: 'FARBY & MIESZALNIA FARB',
          shortDescription:
            'Ochrona metalu i drewna. Farby dekoracyjne. Impregnaty.',
          description: [
            'farby dekoracyjne i przemysłowe do stosowania wewnątrz i na zewnątrz pomieszczeń',
            'lakiery renowacyjne samochodowe',
            'impregnaty do konserwacji i zabezpieczania drewna',
            'lakiery rozpuszczalnikowe, akrylowe, poliuretanowe, wewnętrzne i zewnętrzne',
            'rozpuszczalniki, bejce',
            'Stwórz własny świat używając produktów marki Tikkurila. Malowanie to sposób na zabezpieczenie i podniesienie standardu Twojego domu, zarówno wewnątrz, jak i na zewnątrz. Wybór kolorów jest właściwie nieograniczony, a używając odpowiednich produktów, narzędzi i dzięki kilku cennym poradom wiele możesz zrobić sam.',
            'Profesjonalnym malarzom służymy radą i udzielamy informacji o najnowszych trendach. Dysponujemy także bardziej dokładnymi informacjami na temat technik malarskich w budownictwie oraz w przemyśle.',
          ],
          image: '10.b.webp',
          imageFull: '10.a.webp',
        },
        {
          name: 'CHEMIA BUDOWLANA',
          shortDescription:
            'Zaprawy klejowe, murarskie, gips, silikony, pianki.',
          description: [
            'kleje, masy, gipsy, cement, szpachle, fugi',
            'silikony, uszczelniacze, pianki pistoletowe, kleje',
            'odrdzewiacze, preparaty do usuwania powłok malarskich',
            'narożniki aluminiowe, listwy wykończeniowe',
          ],
          image: '11.b.webp',
          imageFull: '11.a.webp',
        },
      ],
    };
  },
  methods: {
    openAssortmentModal(index) {
      this.modalIndex = index;
      this.modalOpen = true;
    },

    closeAssortmentModal() {
      this.modalIndex = null;
      this.modalOpen = false;
    },
  },
  components: {
    headerItem: () => import('./functionalComponents/HeaderItem'),
    assortmentItem: () => import('./functionalComponents/AssortmentItem'),
    assortmentModal: () => import('./functionalComponents/AssortmentModal'),
  },
};
</script>

<style lang="scss" scoped>
.assortment-container {
  height: auto;
  margin: 0 auto;
  padding: 5rem 1.25rem;
}

@media only screen and (max-width: 768px) {
  .assortment-container {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  .assortment-container {
    width: 80%;
  }
}
</style>
